<template>
  <div>
    <a-modal
      :visible="visible"
      title="标签处理"
      :width="700"
      @cancel="handleCancel"
      @ok="submit"
      :confirmLoading="confirmLoading"
    >
      <a-form-model
        :model="form"
        ref="ruleForm"
        layout="horizontal"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="标签名" ref="title" prop="title">
          <a-input v-model="form.title" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { addVulTag, updateVulTag } from "@/api/wiki";
export default {
  data() {
    return {
      id: 0,
      visible: false,
      confirmLoading: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        title: "",
      },
      rules: {
        title: [{ required: true, message: "标签不能为空", trigger: "blur" }],
      },
    };
  },
  methods: {
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.confirmLoading = true;
          if (this.id === 0) {
            addVulTag(this.form)
              .then((res) => {
                if (res.status === 200) {
                  this.$message.error(res.data.message);
                }
                if (res.status === 201) {
                  this.$message.success("添加成功");
                  this.$parent.$parent.fetch({
                    page: this.$parent.$parent.current,
                    page_size: this.$parent.$parent.pageSize,
                  });
                  this.visible = false;
                  this.$refs["ruleForm"].resetFields();
                }
              })
              .catch((err) => {
                this.$message.error(err.toString());
              });
          } else {
            updateVulTag(this.id, this.form)
              .then((res) => {
                if (res.data.message === undefined) {
                  this.$message.success("修改成功");
                  this.$parent.$parent.fetch({
                    page: this.$parent.$parent.current,
                    page_size: this.$parent.$parent.pageSize,
                  });
                  this.visible = false;
                  this.$refs["ruleForm"].resetFields();
                } else {
                  this.$message.error(res.data.message);
                }
              })
              .catch((err) => {
                this.$message.error(err.toString());
              });
          }
          this.confirmLoading = false;
        }
      });
    },
    handleCancel() {
      this.visible = false;
      this.$refs["ruleForm"].resetFields();
    },
    init(id = 0, title = "") {
      this.id = id;
      this.form.title = title;
      this.visible = true;
    },
  },
};
</script>
