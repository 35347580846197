<template>
  <div>
    <a-card :bordered="false" style="width: 100%">
      <a-button type="primary" icon="plus" @click="add">添加标签</a-button>
      <a-input-search
        placeholder="全局搜索"
        style="width: 300px; float: right"
        v-model="globalSearch"
        @search="onSearch"
      />
      <a-table
        ref="table"
        style="width: 100%; margin-top: 10px"
        size="small"
        :rowKey="(tag) => tag.id"
        :columns="columns"
        :data-source="data"
        showPagination="auto"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        bordered
      >
        <span slot="created_at" slot-scope="text">
          {{ changeTime(text) }}
        </span>
        <span slot="updated_at" slot-scope="text">
          {{ changeTime(text) }}
        </span>
        <span slot="action" slot-scope="tag">
          <a-button type="primary" @click="editTag(tag)" size="small"
            >编辑</a-button
          >
          <a-button
            type="danger"
            @click="deleteTag(tag)"
            size="small"
            style="margin-left: 5px"
            >删除</a-button
          >
        </span>
      </a-table>
      <tag-add ref="tagAdd" />
    </a-card>
  </div>
</template>

<script>
import { changeTime } from "@/libs/utils";
import TagAdd from "./modules/TagAdd";
import { getVulTag, deleteVulTag } from "@/api/wiki";
import { Modal } from "ant-design-vue";
export default {
  components: {
    TagAdd,
  },
  data() {
    this.changeTime = changeTime;
    return {
      current: 1,
      pageSize: 10,
      globalSearch: "",
      data: [],
      loading: false,
      columns: [
        {
          title: "编号",
          dataIndex: "id",
          ellipsis: true,
          width: "20%",
        },
        {
          title: "标签名",
          dataIndex: "title",
          ellipsis: true,
          width: "20%",
        },
        {
          title: "创建时间",
          dataIndex: "created_at",
          ellipsis: true,
          scopedSlots: { customRender: "created_at" },
          width: "20%",
        },
        {
          title: "更新时间",
          dataIndex: "updated_at",
          ellipsis: true,
          scopedSlots: { customRender: "updated_at" },
          width: "20%",
        },
        {
          title: "操作",
          width: "20%",
          scopedSlots: { customRender: "action" },
        },
      ],
      pagination: {
        defaultPageSize: 10,
        showQuickJumper: true,
        showTotal: (total) => `共${total}条数据`,
        showSizeChanger: true,
        pageSizeOptions: ["10", "50", "100", "200"],
        onShowSizeChange: (current, pageSize) => (
          (this.pageSize = pageSize), (this.current = current)
        ),
      },
    };
  },
  methods: {
    handleTableChange(pagination) {
      this.fetch({ page: pagination.current, page_size: pagination.pageSize });
    },
    add() {
      this.$refs["tagAdd"].init();
    },
    onSearch() {
      this.fetch({ page: this.current, page_size: this.pageSize });
    },
    editTag(tag) {
      this.$refs["tagAdd"].init(tag.id, tag.title);
    },
    deleteTag(tag) {
      Modal.confirm({
        title: "警告",
        content: "您确定要删除此标签吗?",
        onOk: () => {
          deleteVulTag(tag.id)
            .then((res) => {
              if (res.data.message === undefined) {
                this.$message.success("删除成功");
                this.fetch({ page: this.current, page_size: this.pageSize });
              } else {
                this.$message.error(res.data.message);
              }
            })
            .catch((err) => {
              this.$message.error(err.toString());
            });
        },
        onCancel() {},
      });
    },
    fetch(query = { page: 1, page_size: 10 }) {
      this.loading = true;
      query["search"] = this.globalSearch;
      getVulTag(query)
        .then((res) => {
          const pagination = { ...this.pagination };
          pagination.total = parseInt(res.data.count);
          this.loading = false;
          this.data = res.data.results;
          this.pagination = pagination;
        })
        .catch((err) => {
          this.$message.error(err.toString());
          this.loading = false;
        });
    },
  },
  mounted() {
    this.fetch({ page: this.current, page_size: this.pageSize });
  },
};
</script>
